.node__branch > path,
.node__root > path,
.node__leaf > path {
    display: none !important; /* ซ่อนเส้น */
}

/* กำหนดขนาดของภาพและลักษณะการแสดงผลในโหนดต่างๆ */
.node__root img {
    width: 100px;
    height: 200px;
    object-fit: fill; /* ทำให้ภาพไม่บิดเบี้ยว */
}

.node__branch img {
    width: 100px;
    height: 200px;
    object-fit: fill;
}

.node__leaf img {
    width: 100px;
    height: 200px;
    object-fit: fill;
}

/* Wrapper ของต้นไม้ให้เต็มหน้าจอ */
#treeWrapper {
    width: 100%;
    height: 100vh;
    position: relative;
}
